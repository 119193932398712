import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Antd from "ant-design-vue";
// import 'ant-design-vue/dist/antd.css';
import hasPermission from './utils/btnJuris'
// import { ElCascader } from 'element-plus';
// import 'element-plus/packages/theme-chalk/src/base.scss'
import SvgIcon from '@/components/SvgIcon.vue'
import vue3TreeOrg from 'vue3-tree-org';
import "vue3-tree-org/lib/vue3-tree-org.css";
import { 
  Button,
  Icon,
  Divider,
  Layout,
  Space,
  Breadcrumb,
  Dropdown,
  Menu,
  PageHeader,
  Pagination,
  Steps,
  Cascader,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  TimePicker,
  Transfer,
  TreeSelect,
  Upload,
  Avatar,
  Badge,
  Calendar,
  Card,
  Descriptions,
  Empty,
  Image,
  List,
  Popover,
  Table,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
  Tree,
  Alert,
  Modal,
  Popconfirm,
  Spin,
  DatePicker,
  ConfigProvider,
  Col,
  Row,
  Progress,
  statistic,
  Carousel
  } from 'ant-design-vue';

import {
  JSEncrypt
} from 'encryptlong'

const app = createApp(App);
app.config.productionTip = false;

window.$encryptByPublicKey = function (content) {
  let encrypt = new JSEncrypt();
  encrypt.setPublicKey("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCISLP98M/56HexX/9FDM8iuIEQozy6kn2JMcbZS5/BhJ+U4PZIChJfggYlWnd8NWn4BYr2kxxyO8Qgvc8rpRZCkN0OSLqLgZGmNvoSlDw80UXq90ZsVHDTOHuSFHw8Bv//B4evUNJBB8g9tpVxr6P5EJ6FMoR/kY2dVFQCQM4+5QIDAQAB")
  return encrypt.encryptLong(content);
};
// app.component(ElCascader.name, ElCascader);
app.component('svg-icon', SvgIcon)
// app.use(store).use(router).use(Antd).use(hasPermission).mount('#app')
app
.use(store)
.use(router)
.use(Button)
.use(Icon)
.use(Divider)
.use(Layout)
.use(Space)
.use(Breadcrumb)
.use(Dropdown)
.use(Menu)
.use(PageHeader)
.use(Pagination)
.use(Steps)
.use(Cascader)
.use(Checkbox)
.use(Form)
.use(Input)
.use(InputNumber)
.use(Radio)
.use(Select)
.use(Switch)
.use(TimePicker)
.use(Transfer)
.use(TreeSelect)
.use(Upload)
.use(Avatar)
.use(Badge)
.use(Calendar)
.use(Card)
.use(Descriptions)
.use(Empty)
.use(Image)
.use(List)
.use(Popover)
.use(Table)
.use(Tabs)
.use(Tag)
.use(Timeline)
.use(Tooltip)
.use(Tree)
.use(Alert)
.use(Modal)
.use(Popconfirm)
.use(Spin)
.use(DatePicker)
.use(ConfigProvider)
.use(Col)
.use(Row)
.use(Progress)
.use(statistic)
.use(hasPermission)
.use(vue3TreeOrg)
.use(Carousel)
.mount('#app')