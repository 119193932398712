/*
 * 登录相关接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例

//登录
export const selectUserForLogin = (param) => {
  return axios.post(`/api/userLogin/selectUserForLogin`, param);
}

//退出登录
export const selectUserForLoginOut = (param) => {
  return axios.post(`/api/userLogin/selectUserForLoginOut`, param);
}

//修改密码
export const updateUserLoginPwd = (param) => {
  return axios.post(`/api/userLogin/updateUserLoginPwd`, param);
}

/*
 * 用户相关接口列表
 */

//查询项目名称
export const selectAllProject = (param) => {
  return axios.post(`/wlapi/userLogin/selectAllProject`, param);
}
//修改项目状态
export const updateProjectState = (param) => {
  return axios.post(`/wlapi/userLogin/updateProjectState`, param);
}

/*
 * 申请注册接口
 */
//申请注册接口
export const insertRegister = (param) => {
  return axios.post(`/api/knRegister/insertRegister`, param);
}
//申请注册列表查询
export const selectRegisterByPagination = (param) => {
  return axios.post(`/api/knRegister/selectRegisterByPagination`, param);
}
//申请注册审批
export const updateToRegisterApproval = (param) => {
  return axios.post(`/api/knRegister/updateToRegisterApproval`, param);
}
//申请注册详情
export const registerApplyById = (param) => {
  return axios.get(`/api/knRegister/registerApplyById?id=${param}`);
}