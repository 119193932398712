//获取的菜单转树
export const getMenuTree = (arr, num, bool=false) => {
  let newArr = []
  for(let i = 0; i <= arr.length -1; i++) {
    const item = arr[i]
    item.key = item.menuNo
    if(!item.childMenuList && !item.buttonList) {
      newArr.push(item)
      continue
    }
    if (item.buttonList) {
      if (!bool) { //是否是公司管理员
        item.childMenuList = item.buttonList.map(button => {
          return {
            key: num == 1 ? button.buttonNo : `${button.buttonNo}-${item.menuNo}`,
            floorInSort: button.floorInSort,
            menuIcon: button.buttonIcon,
            menuName: button.buttonName,
            menuNo: button.buttonNo,
            menuTitle: num == 1 ? button.buttonTitle :`${button.buttonTitle}---按钮`,
            menuUrl: null,
            menuType: 3,
            parentMenuNo: item.menuNo,
            idd:button.idd
          }
        })
      } else {
        item.childMenuList = item.buttonList.filter(btn => btn.buttonTitle == '打印材料清单' || btn.buttonTitle == '下载材料清单')
        item.childMenuList = item.childMenuList.map(button => {
          return {
            key: `${button.buttonNo}-${item.menuNo}`,
            floorInSort: button.floorInSort,
            menuIcon: button.buttonIcon,
            menuName: button.buttonName,
            menuNo: button.buttonNo,
            menuTitle: num == 1 ? button.buttonTitle :`${button.buttonTitle}---按钮`,
            menuUrl: null,
            menuType: 3,
            parentMenuNo: item.menuNo,
            idd:button.idd
          }
        })
      }
      newArr.push(item)
      continue
    }
    item.childMenuList = getMenuTree(item.childMenuList,num,bool)
    newArr.push(item)
  }
  return newArr
}
//根据key查找生成树结构
export const treeFilter =  (tree, func) => {
  return tree.map(node => ({ ...node })).filter(node => {
    node.childMenuList = node.childMenuList && treeFilter(node.childMenuList, func)
    return func(node) || (node.childMenuList && node.childMenuList.length)
  })
}
//根据树查找key
export const treeForeach = (tree, func) => {
  let node, list = [...tree]
  while (node = list.shift()) {
    func(node)
    node.childMenuList && list.push(...node.childMenuList)
  }
}
//获取按钮list
let bulist = {}
export const getButtons = (arr) => {
  arr.forEach(item => {
    if (item.buttonList) {
      const btnArr = []
      item.buttonList.forEach(ixt => {
        btnArr.push(ixt.buttonTitle)
      })
      // bulist.push({[item.menuName]:btnArr})
      bulist[item.menuName] = btnArr
    } else {
      bulist[item.menuName] = []
    }
    if (item.childMenuList) {
      getButtons(item.childMenuList)
    }
  })
  return bulist
 }