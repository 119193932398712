import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import Cookies from "js-cookie";
import store from '@/store'
import { ListItemMeta } from 'ant-design-vue/lib/list';
import router_import from '../router/router_import' // 获取组件的方法
import layoutContent from '../views/layout/layout-content.vue'
import { message } from 'ant-design-vue';
// const routes = [{
//     path: '/',
//     name: 'Home',
//     component: Home,
//     // redirect: '/personnel-management',
//     // children: [{
//     //     path: '/personnel-management/employees-list',
//     //     name: 'EmployeesList',
//     //     component: () => import( /* webpackChunkName: "EmployeesList" */ '../views/personnelManagement/EmployeesList.vue')
//     //   },
//     //   {
//     //     path: '/attendance-management/attendance-today',
//     //     name: 'attendanceToday',
//     //     component: () => import( /* webpackChunkName: "attendanceToday" */ '../views/attendanceManagement/attendanceToday.vue')
//     //   },
//     //   {
//     //     path: '/personnel-management/visitors-management',
//     //     name: 'visitorsManagement',
//     //     component: () => import( /* webpackChunkName: "visitorsManagement" */ '../views/personnelManagement/visitorsManagement.vue')
//     //   },
//     //   {
//     //     path: '/personnel-management/white-list',
//     //     name: 'whiteList',
//     //     component: () => import( /* webpackChunkName: "whiteList" */ '../views/personnelManagement/whiteList.vue')
//     //   },
//     //   {
//     //     path: '/attendance-management/attendance-list',
//     //     name: 'attendanceList',
//     //     component: () => import( /* webpackChunkName: "attendanceList" */ '../views/attendanceManagement/attendanceList.vue')
//     //   },
//     //   {
//     //     path: '/personnel-management/black-list',
//     //     name: 'blackList',
//     //     component: () => import( /* webpackChunkName: "blackList" */ '../views/personnelManagement/blackList.vue')
//     //   },
//     //   {
//     //     path: '/assessment-management/inspection-report',
//     //     name: 'inspectionReport',
//     //     component: () => import( /* webpackChunkName: "inspectionReport" */ '../views/assessmentManagement/inspectionReport.vue')
//     //   },
//     //   {
//     //     path: '/assessment-management/payroll',
//     //     name: 'payroll',
//     //     component: () => import( /* webpackChunkName: "payroll" */ '../views/assessmentManagement/payroll.vue')
//     //   },
//     //   {
//     //     path: '/data-management/constructio-log',
//     //     name: 'ConstructioLog',
//     //     component: () => import( /* webpackChunkName: "ConstructioLog" */ '../views/dataManagement/ConstructioLog.vue')
//     //   },
//     //   {
//     //     path: '/data-management/security-log',
//     //     name: 'SecurityLog',
//     //     component: () => import( /* webpackChunkName: "SecurityLog" */ '../views/dataManagement/ConstructioLog.vue')
//     //   },
//     //   {
//     //     path: '/data-management/other-information',
//     //     name: 'OtherInformation',
//     //     component: () => import( /* webpackChunkName: "OtherInformation" */ '../views/dataManagement/ConstructioLog.vue')
//     //   },
//     //   //材料清单
//     //   {
//     //     path: '/material-management/material-list',
//     //     name: 'materialList',
//     //     component: () => import( /* webpackChunkName: "materialList" */ '../views/materialManagement/materialList.vue')
//     //   },
//     //   //进场管理
//     //   {
//     //     path: '/material-management/approach-management',
//     //     name: 'approachManagement',
//     //     component: () => import( /* webpackChunkName: "approachManagement" */ '../views/materialManagement/approachManagement.vue')
//     //   },
//     //   //岗位管理
//     //   {
//     //     path: '/authority-management/post-allocation',
//     //     name: 'postAllocation',
//     //     component: () => import( /* webpackChunkName: "postAllocation" */ '../views/authorityManagement/postAllocation.vue')
//     //   },
//     //   //用户组权限配置
//     //   {
//     //     path: '/authority-management/permission-configuration',
//     //     name: 'permissionConfiguration',
//     //     component: () => import( /* webpackChunkName: "permissionConfiguration" */ '../views/authorityManagement/permissionConfiguration.vue')
//     //   },
//     //   //菜单管理
//     //   {
//     //     path: '/authority-management/menu-management',
//     //     name: 'menuManagement',
//     //     component: () => import( /* webpackChunkName: "menuManagement" */ '../views/authorityManagement/menuManagement.vue')
//     //   },
//     // ]
//     children: routerArr
//   },
//   {
//     path: '/login',
//     name: 'Login',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue')
//   },
//   {
//     path: '/downloadApp',
//     name: 'downloadApp',
//     component: () => import( /* webpackChunkName: "downloadApp" */ '../views/downloadApp.vue')
//   }
// ]
export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // redirect: '/personnel-management/employees-list',
    children: [ ]
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/downloadApp',
    name: 'downloadApp',
    component: () => import( /* webpackChunkName: "downloadApp" */ '../views/downloadApp.vue')
  },
  {
    path: '/currentHeadcount',
    name: 'currentHeadcount',
    component: () => import( /* webpackChunkName: "currentHeadcount" */ '../views/attendanceManagement/currentHeadcount')
  },
  {
    path: '/requestAccess',
    name: 'requestAccess',
    component: () => import( /* webpackChunkName: "requestAccess" */ '../views/h5/requestAccess.vue')
  },
  {
    path: '/loadPage',
    name: 'loadPage',
    component: () => import( /* webpackChunkName: "loadPage" */ '../views/h5/loadPage.vue')
  },
]

var data = false
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 注册全局钩子用来拦截导航
router.beforeEach((to, from, next) => {
  let token = Cookies.get('token');
  if (to.name === 'downloadApp') {
    return next()
  }
  if (to.name === 'requestAccess') {
    return next()
  }
  if (to.name === 'loadPage') {
    return next()
  }
  if (to.name !== 'Login' && !token) {
    next({
      name: 'Login'
    })
  } else {
    if (store.state.user.isUpdateMenus) {
      next()
    } else if (to.name == 'Login') {
      next()
    }else {
        // store.state.user.isUpdateMenus为false,一定没有获取动态路由,就跳转到获取动态路由的方法
        gotoRouter(to, next)
    }
  }
    // next()
})
const gotoRouter = (to, next) => {
  store.dispatch('getMenus')
    .then((res) => {
      let routeList = listToTree(res)
      routeList.forEach((item) => {
        // 设置模块重定向到菜单
        if (item.children.length > 0 && !item.redirect) {
          item.redirect = { name: item.children[0].name }
        }
      })
      const homeData = routes.find((item) => item.name == 'Home')
      homeData.children = [...routeList]
      homeData.redirect = { name: homeData.children[0].name }
      router.addRoute(homeData)
      // console.log(routes)
      store.dispatch('setUpdateMenusState',true)
      if(!to.name){ //刷新的时候name为undefind,需要还在当前页要拿之前的to
        next({
          ...to,
          replace: true
        })
      }else { //登录时防止需要点击两次登录按钮拿redirect里的值
        if(to.name== 'currentHeadcount') {
          next({
            ...to,
            replace: true
          })
        }else {
          next({
            name:homeData.redirect.name,
            replace: true
          }) // hack方法 确保addRoutes已完成
        }
      }    
    })
    .catch(e => {
      message.error('权限配置有误，请联系管理员')
        // console.log(e)
    })
}
const listToTree = (arr) => {
  let tree = [];
  arr.forEach(item => {
    let obj = {
      path: item.menuUrl,
      name: item.menuName,
      component: item.menuType == 2 ? router_import(item.menuName) : layoutContent,
    };
    if (item.childMenuList && item.childMenuList.length) {
      obj.children = listToTree(item.childMenuList);
    }
    tree.push(obj);
  });
  return tree;
}
export default router