import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { UserOutlined, SnippetsOutlined, CopyOutlined, MenuUnfoldOutlined, MenuFoldOutlined, LockOutlined, QuestionCircleOutlined, BellOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, onMounted, reactive, toRefs, computed, nextTick, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { selectHySettingsList, updateHySettingsByParams, selectDeviceSettings, updateHyDeviceSettings, selectDeviceByIp, selectNotUserDevices, selectHyNotify, selectNotifyCount, selectSpaceByBelongProject } from '../api/global';
import { selectUserForLoginOut, updateUserLoginPwd, selectAllProject, updateProjectState } from '../api/login';
import Cookies from "js-cookie";
import { useStore } from 'vuex';
import { companyAdmin, projectOver } from '@/utils/perJurisdiction';
import meun from './layout/menu/menu.vue';
export default defineComponent({
  name: 'Home',
  components: {
    UserOutlined,
    SnippetsOutlined,
    CopyOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    QuestionCircleOutlined,
    meun,
    BellOutlined
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore(); //通道设置

    const channelVisible = ref(false);
    const formRefChannel = ref();
    const deviceNoList = ref([]);
    const formStateTypes = reactive({
      channelTypes: '0',
      deviceNo: [],
      deviceNo1: [],
      deviceNo2: []
    });

    const toArrString = arr => {
      let newArr = [];
      arr.filter(item => {
        newArr.push(item);
      });

      if (newArr != []) {
        newArr = newArr.toString();
      } else {
        newArr = '';
      }

      return newArr;
    };

    const onSubmitTypes = () => {
      formRefChannel.value.validate().then(() => {
        let arr = [];

        if (formStateTypes.channelTypes == 0) {
          arr = [{
            deviceNo: toArrString(formStateTypes.deviceNo),
            deviceType: null
          }];
        } else {
          let er = 0;
          formStateTypes.deviceNo1.filter(item1 => {
            formStateTypes.deviceNo2.filter(item2 => {
              if (item1 == item2) {
                er = 1;
              }
            });
          });

          if (er == 1) {
            _message.error('进出面板机所选SN号有重合，请修改！');

            return;
          }

          arr = [{
            deviceNo: toArrString(formStateTypes.deviceNo1),
            deviceType: 0
          }, {
            deviceNo: toArrString(formStateTypes.deviceNo2),
            deviceType: 1
          }];
        }

        let setData = {
          channelType: Number(formStateTypes.channelTypes),
          ipAddrs: arr
        };
        changeChannel(setData);
      }).catch(error => {
        console.log('error', error);
      });
    };

    const handleCancelChannel = () => {
      channelVisible.value = false;
      formRefChannel.value.resetFields();
      deviceNoList.value = [];
    };

    const timeVisible = ref(false);

    const onSubmitTime = () => {
      formRefTime.value.validate().then(() => {
        let updataTime = {
          id: formStateTime.id,
          workStartAmTime: formStateTime.workStartAmTime.slice(0, 5),
          workEndAmTime: formStateTime.workEndAmTime.slice(0, 5),
          workStartPmTime: formStateTime.workStartPmTime.slice(0, 5),
          workEndPmTime: formStateTime.workEndPmTime.slice(0, 5),
          overTime: formStateTime.overTime.slice(0, 5),
          overEndTime: formStateTime.overEndTime == '' || formStateTime.overEndTime == null ? null : formStateTime.overEndTime.slice(0, 5)
        };
        updateHySettingsByParams1(updataTime);
      }).catch(error => {
        console.log('error', error);
      });
    };

    const formStateTime = reactive({
      id: '',
      workStartAmTime: '',
      workEndAmTime: '',
      workStartPmTime: '',
      workEndPmTime: '',
      overTime: '',
      overEndTime: ''
    });

    const handleCancelTime = () => {
      timeVisible.value = false; //formRefChannel.value.resetFields();
    }; //退出登录


    const loginOut = async () => {
      let userName = Cookies.get('userName');
      let belongProject = Cookies.get('belongProject');
      let isOnjob = JSON.parse(Cookies.get('isOnjob'));
      let type = null;

      switch (Cookies.get('isCompanyAdmin')) {
        case '0':
          type = 0;
          break;

        case '1':
          type = 0;
          break;

        case '2':
          type = 1;
          break;

        case '3':
          type = 1;
          break;
      }

      let res = await selectUserForLoginOut({
        userName,
        belongProject,
        isOnjob,
        type
      });

      if (res.code === 200) {
        _message.success(res.message); //是否记住密码，记住不清除userName


        if (Cookies.get('rememberPassword')) {
          Cookies.remove('token');
          Cookies.remove('belongProject');
          router.push('/login');
        } else {
          Cookies.remove('userName');
          Cookies.remove('token');
          Cookies.remove('belongProject');
          router.push('/login');
        }

        store.dispatch('setUpdateMenusState', false);
      } else {
        _message.error(res.message);
      }
    }; //修改密码


    const btnLoading = ref(false);
    const visible = ref(false);
    const formRef = ref();
    const formRefTime = ref();
    const passwordState = reactive({
      userName: Cookies.get('userName'),
      oldPassword: '',
      newPassword: '',
      secondPassword: ''
    });

    const handleOk = () => {
      formRef.value.validate().then(() => {
        const oldPwdRsa = window.$encryptByPublicKey(passwordState.oldPassword);
        const passwordRsa = window.$encryptByPublicKey(passwordState.newPassword);
        let belongProject = Cookies.get('belongProject');
        let isOnjob = JSON.parse(Cookies.get('isOnjob'));
        let changePasswordState = {
          userName: passwordState.userName,
          oldPwd: oldPwdRsa,
          userPassword: passwordRsa,
          belongProject,
          isOnjob,
          type: null
        };

        switch (Cookies.get('isCompanyAdmin')) {
          case '0':
            changePasswordState.type = 0;
            break;

          case '1':
            changePasswordState.type = 0;
            break;

          case '2':
            changePasswordState.type = 1;
            break;

          case '3':
            changePasswordState.type = 1;
            break;
        } // console.log(Cookies.get('isCompanyAdmin'),changePasswordState)


        updateUserLoginPwd1(changePasswordState);
      }).catch(error => {
        console.log('error', error);
      });
    };

    const updateUserLoginPwd1 = async val => {
      btnLoading.value = true;
      let res = await updateUserLoginPwd(val);

      if (res.code === 200) {
        btnLoading.value = false;

        _message.success('密码修改成功,请重新登录');

        loginOut();
      } else {
        btnLoading.value = false;

        _message.error(res.message);
      }
    };

    let validatePass3 = async (rule, value) => {
      if (value === '') {
        return Promise.reject('密码不能为空！');
      } else {
        if (passwordState.newPassword !== value) {
          return Promise.reject('两次输入的密码不一致！');
        }

        return Promise.resolve();
      }
    };

    const rules = {
      oldPassword: [{
        required: true,
        message: '请输入原密码',
        trigger: 'blur'
      }],
      newPassword: [{
        required: true,
        message: '请输入新密码',
        trigger: 'blur'
      }],
      secondPassword: [{
        required: true,
        validator: validatePass3,
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }]
    };
    const rulesChannel = {
      channelTypes: [{
        required: true,
        message: '请选择通道类型',
        trigger: 'change'
      }],
      deviceNo: [{
        required: true,
        message: '请选择面板机设备编号',
        trigger: 'change'
      }],
      deviceNo1: [{
        required: true,
        message: '请选择面板机设备编号',
        trigger: 'change'
      }],
      deviceNo2: [{
        required: true,
        message: '请选择面板机设备编号',
        trigger: 'change'
      }]
    };
    const rulesTime = {
      workStartAmTime: [{
        required: true,
        message: '请选择上午上班时间',
        trigger: 'change'
      }],
      workEndAmTime: [{
        required: true,
        message: '请选择上午下班时间',
        trigger: 'change'
      }],
      workStartPmTime: [{
        required: true,
        message: '请选择下午上班时间',
        trigger: 'change'
      }],
      workEndPmTime: [{
        required: true,
        message: '请选择下午下班时间',
        trigger: 'change'
      }],
      overTime: [{
        required: true,
        message: '请选择加班开始时间',
        trigger: 'change'
      }]
    };

    const handleCancel = () => {
      visible.value = false;
      formRef.value.resetFields();
      Object.assign(passwordState, {
        userName: Cookies.get('userName'),
        oldPassword: '',
        newPassword: ''
      });
    }; //获取工作时间


    const selectHySettingsList1 = async () => {
      timeVisible.value = true;
      let res = await selectHySettingsList();

      if (res.code === 200) {
        let data = res.data[0];
        Object.assign(formStateTime, {
          id: data.id,
          workStartAmTime: data.workStartAmTime == '' || data.workStartAmTime == null ? '' : data.workStartAmTime + ':00',
          workEndAmTime: data.workEndAmTime == '' || data.workEndAmTime == null ? '' : data.workEndAmTime + ':00',
          workStartPmTime: data.workStartPmTime == '' || data.workStartPmTime == null ? '' : data.workStartPmTime + ':00',
          workEndPmTime: data.workEndPmTime == '' || data.workEndPmTime == null ? '' : data.workEndPmTime + ':00',
          overTime: data.overTime == '' || data.overTime == null ? '' : data.overTime + ':00',
          overEndTime: data.overEndTime == '' || data.overEndTime == null ? '' : data.overEndTime + ':00'
        });
      } else {
        _message.error(res.message);
      }
    }; //修改工作时间


    const updateHySettingsByParams1 = async val => {
      btnLoading.value = true;
      let res = await updateHySettingsByParams(val);

      if (res.code === 200) {
        btnLoading.value = false;

        _message.success(res.message);

        handleCancelTime();
      } else {
        btnLoading.value = false;

        _message.error(res.message);
      }
    }; //打开通道设置


    const openChannel = () => {
      channelVisible.value = true;
      channelSet();
      selectNotUserDevices1();
    }; //获取通道设置


    const channelSet = async () => {
      channelVisible.value = true;
      let res = await selectDeviceSettings();

      if (res.code === 200) {
        console.log(res.data);
        let data = res.data.ipAddrs;

        if (data.length == 1) {
          Object.assign(formStateTypes, {
            channelTypes: res.data.channelType,
            deviceNo: data[0].deviceNo == "" ? [] : data[0].deviceNo.split(","),
            deviceNo1: [],
            deviceNo2: []
          });
        } else if (data.length == 2) {
          Object.assign(formStateTypes, {
            channelTypes: res.data.channelType,
            deviceNo: [],
            deviceNo1: data[0].deviceType == 0 ? data[0].deviceNo == "" ? [] : data[0].deviceNo.split(",") : data[1].deviceNo == "" ? [] : data[1].deviceNo.split(","),
            deviceNo2: data[0].deviceType == 0 ? data[1].deviceNo == "" ? [] : data[1].deviceNo.split(",") : data[0].deviceNo == "" ? [] : data[0].deviceNo.split(",")
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //修改通道设置


    const changeChannel = async val => {
      btnLoading.value = true;
      console.log(val);
      let res = await updateHyDeviceSettings(val);

      if (res.code === 200) {
        btnLoading.value = false;

        _message.success(res.message);

        handleCancelChannel();
      } else {
        btnLoading.value = false;

        _message.error(res.message);
      }
    }; //获取面板机列表


    const selectNotUserDevices1 = async () => {
      let res = await selectNotUserDevices();

      if (res.code === 200) {
        if (res.data != []) {
          res.data.filter((item, index) => {
            deviceNoList.value.push({
              value: item.deviceNo,
              label: item.deviceNo
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //判断是否是总站


    const isMasterStation = computed(() => store.state.user.isCompanyAdmin);
    const allProjectName = ref([]); //查询项目名称

    const selectAllProjectName = async () => {
      const res = await selectAllProject();

      if (res.code === 200) {
        allProjectName.value = [];

        if (res.data.length) {
          res.data.forEach(item => {
            allProjectName.value.push({
              value: item.id,
              label: item.projectName,
              projectState: item.projectState
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const isRouterAlive = ref(true); //选择站

    const stationName = ref(Number(Cookies.get("belongProject")));

    const handleChangeStation = value => {
      Cookies.set("belongProject", value, {
        expires: 356,
        path: "/"
      });

      if (value === 10000) {
        Cookies.set("btnPer", companyAdmin, {
          expires: 356,
          path: "/"
        });
      } else {
        Cookies.set("btnPer", projectOver, {
          expires: 356,
          path: "/"
        });
      } //选择站后重新渲染


      isRouterAlive.value = false;
      nextTick(function () {
        isRouterAlive.value = true;
      });
    }; //项目状态


    const stationStatusFn = computed(() => {
      if (allProjectName.value.length) {
        const obj = allProjectName.value.find(item => item.value === stationName.value);

        if (obj.projectState === 1) {
          return '正常运行';
        } else {
          return '完结';
        }
      }
    }); //项目完结

    const setProjectState = async val => {
      const param = {
        belongProject: Number(Cookies.get("belongProject")),
        projectState: val
      };
      const res = await updateProjectState(param);

      if (res.code === 200) {
        _message.success(res.message); //调完接口返回成功后退出登录


        loginOut();
      } else {
        _message.error(res.message);
      }
    }; //管理员登录获取的项目状态


    const projectState = computed(() => store.state.user.projectState);

    const handleClickBeOver = () => {
      setProjectState(0);
    };

    const handleClickBeOpen = () => {
      setProjectState(1);
    };

    const websocketObj = reactive({
      websock: null,
      //建立的连接
      lockReconnect: false,
      //是否真正建立连接
      timeout: 300 * 1000,
      //5分钟一次心跳
      timeoutObj: null,
      //外层心跳倒计时
      serverTimeoutObj: null,
      //内层心跳检测
      timeoutnum: null //断开 重连倒计时

    });

    const initWebSocket = () => {
      websocketObj.websock = null;
      const {
        protocol,
        host
      } = location; //判断当前浏览器是否支持WebSocket

      if ("WebSocket" in window) {
        websocketObj.websock = new WebSocket(`${protocol === 'https:' ? 'wss' : 'ws'}://${host}/personnelapi/websocket/${Cookies.get("uid")}`);
      } else {
        alert("您的浏览器不支持websocket");
      }

      websocketObj.websock.onopen = websocketonopen;
      websocketObj.websock.onmessage = websocketonmessage;
      websocketObj.websock.onerror = websocketonerror;
      websocketObj.websock.onclose = websocketclose;
    }; //重连


    const reconnect = () => {
      if (websocketObj.lockReconnect) {
        //是否真正建立连接
        return;
      }

      websocketObj.lockReconnect = true; //没连接上会一直重连，设置延迟避免请求过多

      websocketObj.timeoutnum && clearTimeout(websocketObj.timeoutnum); // 如果到了这里断开重连的倒计时还有值的话就清除掉

      websocketObj.timeoutnum = setTimeout(function () {
        // console.log(11111111111)
        //然后新连接
        initWebSocket();
        websocketObj.lockReconnect = false;
      }, 120000);
    }; //重置心跳


    const reset = () => {
      //清除时间（清除内外两个心跳计时）
      clearTimeout(websocketObj.timeoutObj);
      clearTimeout(websocketObj.serverTimeoutObj); //重启心跳

      start();
    }; //开启心跳


    const start = () => {
      websocketObj.timeoutObj && clearTimeout(websocketObj.timeoutObj); // 如果外层心跳倒计时存在的话，清除掉

      websocketObj.serverTimeoutObj && clearTimeout(websocketObj.serverTimeoutObj); // 如果内层心跳检测倒计时存在的话，清除掉

      websocketObj.timeoutObj = setTimeout(function () {
        // console.log(222222222)
        // 重新赋值重新发送 进行心跳检测
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (websocketObj.websock.readyState == 1) {
          //如果连接正常
          websocketObj.websock.send('new=0');
        } else {
          //否则重连
          reconnect();
        }

        websocketObj.serverTimeoutObj = setTimeout(function () {
          // 在三秒一次的心跳检测中如果某个值3秒没响应就关掉这次连接
          //超时关闭
          websocketObj.websock.close();
        }, websocketObj.timeout);
      }, websocketObj.timeout); // 3s一次
    };

    const websocketonopen = e => {
      //连接建立之后执行send方法发送数据
      console.log("成功");
      let actions = 'new=0';
      websocketsend(actions);
    };

    const websocketonerror = () => {
      //连接建立失败重连
      console.log("websocket连接失败");
      reconnect();
    };

    const websocketonmessage = e => {
      //数据接收
      // console.log(e);
      if (e.data == 'new=1') {
        selectNotifyCounts();
      }

      reset();
    };

    const websocketsend = Data => {
      //数据发送
      websocketObj.websock.send(Data);
    };

    const websocketclose = e => {
      //关闭
      console.log("断开连接", e);
    }; //查询消息条数


    const notifyCounts = ref(0);

    const selectNotifyCounts = async () => {
      const res = await selectNotifyCount({
        idCard: Cookies.get("uid")
      });

      if (res.code == 200) {
        notifyCounts.value = res.data.count;
      } else {
        _message.error(res.message);
      }
    }; //通知


    const badgeVisible = ref(false);
    const notifyList = ref([]);

    const handleNotify = async () => {
      const res = await selectHyNotify({
        idCard: Cookies.get("uid")
      });

      if (res.code == 200) {
        notifyCounts.value = 0;
        notifyList.value = [];

        if (res.data.length) {
          res.data.forEach(item => {
            notifyList.value.push({
              message: item.message,
              sendTime: item.sendTime
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      //当前登录是总公司帐号，这获取所有项目名称
      if (isMasterStation.value == '1' || isMasterStation.value == '3') {
        selectAllProjectName();
      } // 当前登录是人员帐号,则开启websocket
      // if(isMasterStation.value =='2' || isMasterStation.value == '3'){
      // }


      selectNotifyCounts();
      initWebSocket();
    });
    const imgeVisible = ref(false);
    const nameState = reactive({
      userName: Cookies.get('userName'),
      nickName: Cookies.get('nickName')
    }); //存储空间

    const spaceVisible = ref(false);
    const spaceInfo = reactive({
      totalSpaceNum: 0,
      usageRate: 0
    });

    const getSpaceInfo = async () => {
      let param = {
        belongProject: Number(Cookies.get('belongProject'))
      };
      let res = await selectSpaceByBelongProject(param);

      if (res.code === 200) {
        spaceInfo.totalSpaceNum = res.data.totalSpaceNum;
        spaceInfo.usageRate = res.data.usageRate;
      } else {
        _message.error(res.message);
      }
    };

    const topTitle = Cookies.get('companyName');
    onUnmounted(() => {
      //当前登录是人员帐号,则关闭websocket
      if (isMasterStation.value == '2' || isMasterStation.value == '3') {
        //清除时间（清除内外两个心跳计时） 
        clearTimeout(websocketObj.timeoutObj);
        clearTimeout(websocketObj.serverTimeoutObj);
        websocketObj.websock.close();
        websocketObj.websock.onclose();
        websocketObj.lockReconnect = true;
        websocketObj.timeoutnum && clearTimeout(websocketObj.timeoutnum);
      }
    });
    return {
      collapsed: ref(false),
      formStateTypes,
      formStateTime,
      onSubmitTypes,
      onSubmitTime,
      selectHySettingsList1,
      loginOut,
      handleOk,
      visible,
      timeVisible,
      channelVisible,
      passwordState,
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      },
      rules,
      rulesChannel,
      handleCancel,
      formRef,
      formRefChannel,
      btnLoading,
      openChannel,
      handleCancelChannel,
      handleCancelTime,
      formRefTime,
      rulesTime,
      deviceNoList,
      isMasterStation,
      stationName,
      handleChangeStation,
      handleClickBeOver,
      allProjectName,
      stationStatusFn,
      projectState,
      handleClickBeOpen,
      isRouterAlive,
      badgeVisible,
      notifyCounts,
      handleNotify,
      notifyList,
      imgeVisible,
      nameState,
      spaceVisible,
      spaceInfo,
      getSpaceInfo,
      topTitle
    };
  }

});