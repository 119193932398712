import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");

  const _component_a_layout_content = _resolveComponent("a-layout-content");

  return _openBlock(), _createBlock(_component_a_layout_content, {
    style: {
      margin: '0 16px 0',
      padding: '24px',
      background: '#fff',
      minHeight: '280px',
      borderRadius: ' 5px'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view)]),
    _: 1
  });
}