import axios from 'axios';
import {
  message
} from 'ant-design-vue';
import Cookies from "js-cookie";
import router from '@/router'
import store from '@/store'
/** 
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
 const toLogin = () => {
  router.replace({
      path: '/login',
      // query: {
      //     redirect: router.currentRoute.fullPath
      // }
  });
}

/** 
 * 请求失败后的错误统一处理 
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (res) => {
  // console.log(res)
  // 状态码判断
  switch (res.status) {
    // 401: 未登录状态，跳转登录页
    // case 401:
    //     toLogin();
    //     break;
    case 200:
      if (res.data.code === -1) {//token失效
        message.error('登录身份已失效,请重新登录!');
        toLogin();
        Cookies.remove('token');
        store.dispatch('setUpdateMenusState',false)
      } else if (res.data.code === 100) {//异地登录
        toLogin();
        Cookies.remove('token');
        store.dispatch('setUpdateMenusState',false)
      }
      break
      // 404请求不存在
      // case 404:
      //     tip('请求的资源不存在'); 
      //     break;
      // 500服务起错误
    case 500:
      message.error('服务器错误');
      break;
    default:
      console.log('服务器错误');
  }
}

const instance = axios.create({
  // process.env.NODE_ENV === 'development' 来判断是否开发环境
  timeout: 1000 * 12
});

// 设置url
instance.defaults.baseURL = process.env.BASE_API;

// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/json';

/** 
 * 请求拦截器 
 * 每次请求前，如果存在token则在请求头中携带token 
 */
instance.interceptors.request.use(
  config => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况        
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token        
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码        
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。        
    const token = Cookies.get('token');
    // const token = store.state.user.token; 
    // console.log(token)
    // token && (config.headers.Authorization = token);      
    token && (config.headers['token'] = token);
    // const idCard = Cookies.get('idCard');   
    const idCard = Cookies.get('uid');   //后端让赋值uid
    idCard && (config.headers['idCardPc'] = idCard);
    const belongProject = Cookies.get('belongProject');
    belongProject && (config.headers['belongProject'] = belongProject);
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject();
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    if (response.status === 200) {
      errorHandle(response)
      return response.data;
    } else {
      // errorHandle(response.status, response.data.message)
      Promise.reject(response);
    }
  },
  error => {
    const {
      response
    } = error;
    errorHandle(response)
    return Promise.reject(error);
  }
);

export default instance;