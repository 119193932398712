import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_menu_item = _resolveComponent("menu-item");

  const _component_a_menu = _resolveComponent("a-menu");

  return _openBlock(), _createBlock(_component_a_menu, {
    theme: "dark",
    mode: "inline",
    selectedKeys: _ctx.selectedKeys,
    onSelect: _ctx.onSelect,
    "inline-collapsed": _ctx.collapsed,
    openKeys: _ctx.openKeys,
    onOpenChange: _ctx.onOpenChange
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, item => {
      return _openBlock(), _createBlock(_component_menu_item, {
        "menu-info": item,
        key: item.menuName,
        collapsed: !_ctx.collapsed
      }, null, 8, ["menu-info", "collapsed"]);
    }), 128))]),
    _: 1
  }, 8, ["selectedKeys", "onSelect", "inline-collapsed", "openKeys", "onOpenChange"]);
}