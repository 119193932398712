import Cookies from "js-cookie";
import store from '@/store'

export default (Vue) => {
  /**自定义按钮权限指令 */
  Vue.directive('has', {
    mounted(el, binding) {
      //获取按钮权限
      if (!Vue.config.globalProperties.$_has(binding.value)) {
        //移除不匹配的按钮
        el.parentNode.removeChild(el)
      }
    },
  })

  //检查权限方法
  Vue.config.globalProperties.$_has = function (value) {
    let isExist = false
    // console.log('sssssss',store.state.user.buttons)
    var btnPermsArr = store.state.user.buttons //获取从服务器请求存储本地的按钮权限
    let arr = value.split(':')
    // console.log(btnPermsArr,arr)
    // console.log(btnPermsArr[arr[0]].includes(arr[1]))
    // console.log(Cookies.get('isCompanyAdmin'), Cookies.get('belongProject'))
    // if (Cookies.get('isCompanyAdmin') == '3') {
    //   isExist =  false
    // } 
    //总公司管理员按钮权限只能有员工列表、黑名单管理、白名单管理的按钮权限
    if (Cookies.get('isCompanyAdmin') == '1' && Cookies.get('belongProject') == '10000') {
      if (btnPermsArr[arr[0]].includes(arr[1])) {
        if (arr[0] === 'EmployeesList' || arr[0] === 'blackList' || arr[0] === 'whiteList') {
          isExist = true
        }
      }
    }
    if (Cookies.get('isCompanyAdmin') == '0' || Cookies.get('isCompanyAdmin') == '2'||Cookies.get('isCompanyAdmin') == '3') {
      if (btnPermsArr[arr[0]].includes(arr[1])) {
        isExist = true
      }
    }
    return isExist
  }
}