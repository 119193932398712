import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");

  const _component_a_menu_item = _resolveComponent("a-menu-item");

  const _component_menu_item = _resolveComponent("menu-item", true);

  const _component_a_sub_menu = _resolveComponent("a-sub-menu");

  return _ctx.menuInfo.childMenuList.length ? (_openBlock(), _createBlock(_component_a_sub_menu, _mergeProps({
    key: 0,
    key: _ctx.menuInfo.menuName
  }, _ctx.$attrs), {
    title: _withCtx(() => [_createElementVNode("span", null, [_createVNode(_component_svg_icon, {
      "icon-class": _ctx.menuInfo.menuIcon
    }, null, 8, ["icon-class"]), _ctx.collapsed ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.menuInfo.menuTitle), 1)) : _createCommentVNode("", true)])]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuInfo.childMenuList, item => {
      return _openBlock(), _createElementBlock(_Fragment, {
        key: item.menuName
      }, [!item.childMenuList ? (_openBlock(), _createBlock(_component_a_menu_item, {
        key: item.menuName
      }, {
        default: _withCtx(() => [_createVNode(_component_svg_icon, {
          "icon-class": item.menuIcon
        }, null, 8, ["icon-class"]), _createElementVNode("span", null, _toDisplayString(item.menuTitle), 1)]),
        _: 2
      }, 1024)) : (_openBlock(), _createBlock(_component_menu_item, {
        key: item.menuName,
        "menu-info": item
      }, null, 8, ["menu-info"]))], 64);
    }), 128))]),
    _: 1
  }, 16)) : (_openBlock(), _createBlock(_component_a_menu_item, {
    key: _ctx.menuInfo.menuTitle
  }, {
    default: _withCtx(() => [_createVNode(_component_svg_icon, {
      "icon-class": _ctx.menuIcon
    }, null, 8, ["icon-class"]), _ctx.collapsed ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.menuInfo.menuTitle), 1)) : _createCommentVNode("", true)]),
    _: 1
  }));
}