import { defineComponent, ref, onMounted, reactive, toRefs, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import MenuItem from './menu-item.vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'meun',
  components: {
    MenuItem
  },
  props: {
    collapsed: {
      // 侧边栏菜单是否收起
      type: Boolean
    }
  },

  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      // rootSubmenuKeys: ['personnel-management', 'attendance-management', 'assessment-management', 'data-management','material-management','authority-management','approval-process'],
      openKeys: [route.path.split('/')[1]],
      selectedKeys: [route.name]
    }); // console.log(state)

    const onSelect = item => {
      router.push({
        name: item.key
      });
    };

    const onOpenChange = openKeys => {
      // console.log('state',state)
      // console.log('openKeys',openKeys)
      const latestOpenKey = openKeys.find(key => state.openKeys.indexOf(key) === -1);

      if (rootSubmenuKeys.value.indexOf(latestOpenKey) === -1) {
        state.openKeys = openKeys;
      } else {
        state.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    };

    const store = useStore();
    const menus = computed(() => store.state.user.menus);
    const rootSubmenuKeys = computed(() => {
      let arr = [];
      store.state.user.menus.forEach(item => {
        arr.push(item.menuName);
      });
      return arr;
    });
    watch(() => {// console.log(selectedKeys)
    });
    const selectedKeys = computed(() => {
      return [route.name];
    });
    return { ...toRefs(state),
      onSelect,
      onOpenChange,
      menus,
      selectedKeys
    };
  }

});