const admin = {
  //员工列表
  EmployeesList: ['add', 'allDelete', 'download', 'allImport', 'modification', 'delete', 'resetPassword', 'synchronous'],
  //资料
  ConstructioLog: ['add', 'labelManagement', 'modification', 'delete'],
  //黑名单
  blackList: ['add', 'delete'],
  //白名单
  whiteList: ['open'],
  //访客
  visitorsManagement: ['add', 'synchronous'],
  //考勤报表
  attendanceList: ['add', 'modification', 'confirm'],
  //考核报表
  inspectionReport:['add', 'delete'],
}
const projectPersonnel = {
  EmployeesList: ['add', 'allDelete', 'download', 'allImport', 'modification', 'delete', 'resetPassword', 'synchronous'],
  ConstructioLog: ['add', 'labelManagement', 'modification', 'delete'],
  //黑名单
  blackList: ['add', 'delete'],
  //白名单
  whiteList: ['open'],
  //访客
  visitorsManagement: ['add', 'synchronous'],
  //考勤报表
  attendanceList: ['add', 'modification', 'confirm'],
  //考核报表
  inspectionReport:['add', 'delete'],
}
const companyAdmin = {
  EmployeesList: ['add', 'allDelete', 'download', 'allImport', 'modification', 'delete', 'resetPassword', 'synchronous'],
  ConstructioLog: [],
  //黑名单
  blackList: [],
  //白名单
  whiteList: [],
  //访客
  visitorsManagement: [],
  //考勤报表
  attendanceList: [],
  //考核报表
  inspectionReport:[],
}
const companypersonnel = {
  EmployeesList: ['add', 'allDelete', 'download', 'allImport', 'modification', 'delete', 'resetPassword', 'synchronous'],
  ConstructioLog: [],
  //黑名单
  blackList: [],
  //白名单
  whiteList: [],
  //访客
  visitorsManagement: [],
  //考勤报表
  attendanceList: [],
  //考核报表
  inspectionReport:[],
}
const projectOver = {
  EmployeesList: [],
  ConstructioLog: [],
  //黑名单
  blackList: [],
  //白名单
  whiteList: [],
  //访客
  visitorsManagement: [],
  //考勤报表
  attendanceList: [],
  //考核报表
  inspectionReport:[],
}
export { admin,projectPersonnel ,companyAdmin,companypersonnel,projectOver}