/*
 * 全局设置接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例
import instance from '@/utils/http';

/**
 * 查询工作时间
 */
export const selectHySettingsList = (param) => {
  return axios.post(`/wlapi/HySettings/selectHySettingsList`, param);
}

/**
 * 修改工作时间
 */
export const updateHySettingsByParams = (param) => {
  return axios.post(`/wlapi/HySettings/updateHySettingsByParams`, param);
}

/**
 * 查询通道设置
 */
export const selectDeviceSettings = (param) => {
  return axios.post(`/wlapi/device/selectDeviceSettings`, param);
}

/**
 * 查询面板机设备编号
 */
export const selectDeviceByIp = (param) => {
  return axios.post(`/wlapi/device/selectDeviceByIp`, param);
}

/**
 * 获取面板机列表
 */
export const selectNotUserDevices = (param) => {
  return axios.post(`/wlapi/device/selectNotUserDevices`, param);
}

/**
 * 修改通道设置
 */
export const updateHyDeviceSettings = (param) => {
  return axios.post(`/wlapi/device/updateHyDeviceSettings`, param);
}

/**
 * 查询消息记录
 */
 export const selectHyNotify = (param) => {
  return axios.post(`/personnelapi/HyNotify/selectHyNotify`, param);
}
/**
 * 查询消息条数
 */
 export const selectNotifyCount = (param) => {
  return axios.post(`/personnelapi/HyNotify/selectNotifyCount`, param);
}

//查询存储空间
 export const selectSpaceByBelongProject = (param) => {
  return axios.post(`/personnelapi/userLogin/selectSpaceByBelongProject`, param);
}