import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import Cookies from "js-cookie";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const locale = ref(zhCN); // const store = useStore()
    // const router = useRouter()
    //   // 不同浏览器 hidden 名称
    // const hiddenProperty = 'hidden' in document ? 'hidden' :    
    //   'webkitHidden' in document ? 'webkitHidden' :    
    //   'mozHidden' in document ? 'mozHidden' :    
    //   null;
    // const onVisibilityChange = () => {
    //   if (!document[hiddenProperty]) {
    //     if (store.state.user.isCompanyAdmin != Cookies.get('isCompanyAdmin')) {
    //       console.log(111111111,store.state.user.isCompanyAdmin,Cookies.get('isCompanyAdmin'))
    //       router.go(0)
    //     }
    //     // console.log('页面非激活');
    //   } else {
    //   }
    // } 
    // // 不同浏览器的事件名
    // const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');
    // onMounted(()=>{
    //   document.addEventListener(visibilityChangeEvent, onVisibilityChange);
    // })
    // onBeforeUnmount(()=>{
    //   document.removeEventListener(visibilityChangeEvent, onVisibilityChange)
    // })

    return {
      locale
    };
  }

};