import { defineComponent } from 'vue';
export default defineComponent({
  name: 'MenuItem',
  props: {
    menuInfo: {
      type: Object,
      default: () => ({})
    },
    collapsed: {
      // 侧边栏菜单是否收起
      type: Boolean
    }
  },

  setup(props) {
    // console.log(props.menuInfo)
    return {};
  }

});