export default file => {
  return map[file] || null
}

const map = {
  EmployeesList: () => import( /* webpackChunkName: "EmployeesList" */ '../views/personnelManagement/EmployeesList.vue'),
  attendanceToday: () => import( /* webpackChunkName: "attendanceToday" */ '../views/attendanceManagement/attendanceToday.vue'),
  visitorsManagement: () => import( /* webpackChunkName: "visitorsManagement" */ '../views/personnelManagement/visitorsManagement.vue'),
  whiteList: () => import( /* webpackChunkName: "whiteList" */ '../views/personnelManagement/whiteList.vue'),
  organizationChart: () => import( /* webpackChunkName: "organizationChart" */ '../views/personnelManagement/organizationChart.vue'),
  organizationManagement: () => import( /* webpackChunkName: "organizationManagement" */ '../views/personnelManagement/organizationManagement.vue'),

  payrollManagement: () => import( /* webpackChunkName: "payrollManagement" */ '../views/personnelManagement/payrollManagement.vue'),

  performanceManagement: () => import( /* webpackChunkName: "performanceManagement" */ '../views/personnelManagement/performanceManagement.vue'),
  leaveRecord: () => import( /* webpackChunkName: "leaveRecord" */ '../views/personnelManagement/leaveRecord.vue'),



  attendanceList: () => import( /* webpackChunkName: "attendanceList" */ '../views/attendanceManagement/attendanceList.vue'),
  setChannel: () => import( /* webpackChunkName: "setChannel" */ '../views/attendanceManagement/setChannel.vue'),
  setWorkTime: () => import( /* webpackChunkName: "setWorkTime" */ '../views/attendanceManagement/setWorkTime.vue'),
  typesetManagement: () => import( /* webpackChunkName: "typesetManagement" */ '../views/attendanceManagement/typesetManagement.vue'),

  riginalAttendanceData: () => import( /* webpackChunkName: "riginalAttendanceData" */ '../views/attendanceManagement/riginalAttendanceData.vue'),
 

  blackList: () => import( /* webpackChunkName: "blackList" */ '../views/personnelManagement/blackList.vue'),
  inspectionReport: () => import( /* webpackChunkName: "inspectionReport" */ '../views/assessmentManagement/inspectionReport.vue'),
  payroll: () => import( /* webpackChunkName: "payroll" */ '../views/assessmentManagement/payroll.vue'),

  ConstructioLog: () => import( /* webpackChunkName: "ConstructioLog" */ '../views/dataManagement/ConstructioLog.vue'),//施工日志
  SecurityLog: () => import( /* webpackChunkName: "SecurityLog" */ '../views/dataManagement/ConstructioLog.vue'),//安全日志
  OtherInformation: () => import( /* webpackChunkName: "OtherInformation" */ '../views/dataManagement/ConstructioLog.vue'),//其他资料

  meetingSummary: () => import( /* webpackChunkName: "meetingSummary" */ '../views/dataManagement/ConstructioLog.vue'),//会议纪要
  workStatement: () => import( /* webpackChunkName: "workStatement" */ '../views/dataManagement/ConstructioLog.vue'),//工作报告
  contractingContract: () => import( /* webpackChunkName: "contractingContract" */ '../views/dataManagement/ConstructioLog.vue'),//承发包合同
  designPaper: () => import( /* webpackChunkName: "designPaper" */ '../views/dataManagement/ConstructioLog.vue'),//设计图纸
  equipmentMaterial: () => import( /* webpackChunkName: "equipmentMaterial" */ '../views/dataManagement/ConstructioLog.vue'),//设备材料资料
  progressPlan: () => import( /* webpackChunkName: "progressPlan" */ '../views/dataManagement/ConstructioLog.vue'),//进度计划
  inspectionAcceptance: () => import( /* webpackChunkName: "inspectionAcceptance" */ '../views/dataManagement/ConstructioLog.vue'),//质检验收
  projectData: () => import( /* webpackChunkName: "projectData" */ '../views/dataManagement/ConstructioLog.vue'),//项目资料
  publicData: () => import( /* webpackChunkName: "publicData" */ '../views/dataManagement/ConstructioLog.vue'),//公共资料
  companyCertificate: () => import( /* webpackChunkName: "companyCertificate" */ '../views/dataManagement/ConstructioLog.vue'),//公司证照
  companyContract: () => import( /* webpackChunkName: "companyContract" */ '../views/dataManagement/ConstructioLog.vue'),//公司合同
  companyProfile: () => import( /* webpackChunkName: "companyProfile" */ '../views/dataManagement/ConstructioLog.vue'),//公司资料
   staffRecord: () => import( /* webpackChunkName: " staffRecord" */ '../views/dataManagement/ConstructioLog.vue'),//员工档案
   customerData: () => import( /* webpackChunkName: " customerData" */ '../views/dataManagement/ConstructioLog.vue'),//客户资料


  materialList: () => import( /* webpackChunkName: "materialList" */ '../views/materialManagement/materialList.vue'),
  approachManagement: () => import( /* webpackChunkName: "approachManagement" */ '../views/materialManagement/approachManagement.vue'),
  warehousingMangement: () => import( /* webpackChunkName: "warehousingMangement" */ '../views/materialManagement/warehousingMangement.vue'),
  deliveryManagement: () => import( /* webpackChunkName: "deliveryManagement" */ '../views/materialManagement/deliveryManagement.vue'),
  exitManagement: () => import( /* webpackChunkName: "exitManagement" */ '../views/materialManagement/exitManagement.vue'),
  postAllocation: () => import( /* webpackChunkName: "postAllocation" */ '../views/authorityManagement/postAllocation.vue'),
  permissionConfiguration: () => import( /* webpackChunkName: "permissionConfiguration" */ '../views/authorityManagement/permissionConfiguration.vue'),
  menuManagement: () => import( /* webpackChunkName: "menuManagement" */ '../views/authorityManagement/menuManagement.vue'),
  accountManagement: () => import( /* webpackChunkName: "accountManagement" */ '../views/authorityManagement/accountManagement.vue'),
  contractApprove: () => import( /* webpackChunkName: "contractApprove" */ '../views/approvalProcess/contractApprove.vue'),
  changeApproval: () => import( /* webpackChunkName: "changeApproval" */ '../views/approvalProcess/changeApproval.vue'),
  paymentApproval: () => import( /* webpackChunkName: "paymentApproval" */ '../views/approvalProcess/paymentApproval.vue'),
  settlementApproval: () => import( /* webpackChunkName: "settlementApproval" */ '../views/approvalProcess/settlementApproval.vue'),
  paymentRecords: () => import( /* webpackChunkName: "paymentRecords" */ '../views/approvalProcess/paymentRecords.vue'),
  biddingManagement: () => import( /* webpackChunkName: "biddingManagement" */ '../views/approvalProcess/biddingManagement.vue'),

  operationManual: () => import( /* webpackChunkName: "operationManual" */ '../views/help/operationManual.vue'),
  feedback: () => import( /* webpackChunkName: "feedback" */ '../views/help/feedback.vue'),


  registrationReview: () => import( /* webpackChunkName: "registrationReview" */ '../views/authorityManagement/registrationReview.vue'),

  workOrder: () => import( /* webpackChunkName: "workOrder" */ '../views/workOrderManagement/workOrder.vue'),
  programManagement: () => import( /* webpackChunkName: "programManagement" */ '../views/workOrderManagement/programManagement.vue'),
  scheduleManagement: () => import( /* webpackChunkName: "scheduleManagement" */ '../views/workOrderManagement/scheduleManagement.vue'),

  logisticsApplication: () => import( /* webpackChunkName: "logisticsApplication" */ '../views/logisticsManagement/logisticsApplication.vue'),


  assetManagement: () => import( /* webpackChunkName: "assetManagement" */ '../views/administrationManagement/assetManagement.vue'),
  outsourcingManagement: () => import( /* webpackChunkName: "outsourcingManagement" */ '../views/administrationManagement/outsourcingManagement.vue'),
  reimbursementApproval: () => import( /* webpackChunkName: "reimbursementApproval" */ '../views/administrationManagement/reimbursementApproval.vue'),
  leaveApproval: () => import( /* webpackChunkName: "leaveApproval" */ '../views/administrationManagement/leaveApproval.vue'),

  travelApproval: () => import( /* webpackChunkName: "travelApproval" */ '../views/administrationManagement/travelApproval.vue'),
  workOvertime: () => import( /* webpackChunkName: "workOvertime" */ '../views/administrationManagement/workOvertime.vue'),
  itemRequest: () => import( /* webpackChunkName: "itemRequest" */ '../views/administrationManagement/itemRequest.vue'),
  vehicleManagement: () => import( /* webpackChunkName: "vehicleManagement" */ '../views/administrationManagement/vehicleManagement.vue'),
  visitorPage: () => import( /* webpackChunkName: "visitorPage" */ '../views/administrationManagement/visitorPage.vue'),

  //个人信息
  myAttendance: () => import( /* webpackChunkName: "myAttendance" */ '../views/personalInformation/myAttendance.vue'),
  mySchedul: () => import( /* webpackChunkName: "mySchedul" */ '../views/personalInformation/mySchedul.vue'),
  myVacation: () => import( /* webpackChunkName: "myVacation" */ '../views/personalInformation/myVacation.vue'),
  myPayroll: () => import( /* webpackChunkName: "myPayroll" */ '../views/personalInformation/myPayroll.vue'),
  myPerformance: () => import( /* webpackChunkName: "myPerformance" */ '../views/personalInformation/myPerformance.vue'),
}