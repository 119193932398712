import Cookies from "js-cookie";
import { getUserMenu, getAuthMenu } from '@/api/role'
// import menuData  from '@/utils/menuData'
import { getButtons } from '@/utils/setMenuTree'
import { message } from 'ant-design-vue'
const user = {
  state: {
    isCompanyAdmin: Cookies.get("isCompanyAdmin"),//是否为管理员，0项目管理员，1公司管理员,2项目人员，3公司人员
    projectState: Cookies.get("projectState"),//项目状态，//0为关闭，1开启
    menus: [],
    buttons: {},
    isUpdateMenus: false,
    bianModalState: false,
    fuModalState: false,
    jieModalState: false
  },
  mutations: {
    setCompanyAdminStatus(state, bool) {
      state.isCompanyAdmin = bool
    },
    setProjectState(state, bool) {
      state.projectState = bool
    },
    setMenus(state, val) {
      state.menus = val
    },
    setButtons(state, val) {
      state.buttons = val
    },
    setUpdateMenusState(state, bool) {
      state.isUpdateMenus = bool
    },
    setBianModalState(state, bool) {
      state.bianModalState = bool
    },
    setFuModalState(state, bool) {
      state.fuModalState = bool
    },
    setJieModalState(state, bool) {
      state.jieModalState = bool
    },
  },
  actions: {
    setCompanyAdminStatus({
      commit
    }, bool) {
      commit('setCompanyAdminStatus', bool)
    },
    setProjectState({
      commit
    }, bool) {
      commit('setProjectState', bool)
    },
    async getMenus({
      commit
    }, val) {
      // 动态获取菜单
      let routers = {}
      routers = await getUserMenu()
      if (!routers.data.menuList.length) {
        message.error('此用户没有权限！')
        return
      }
      commit('setMenus', routers.data.menuList)
      const butList = getButtons(routers.data.menuList)
      commit('setButtons', butList)
      return routers.data.menuList
      // routers = menuData
      // commit('setMenus', routers)
      // return routers
    },
    setUpdateMenusState({
      commit
    }, bool) {
      commit('setUpdateMenusState', bool)
    },
    setBianModalState({
      commit
    }, bool) {
      commit('setBianModalState', bool)
    },
    setFuModalState({
      commit
    }, bool) {
      commit('setFuModalState', bool)
    },
    setJieModalState({
      commit
    }, bool) {
      commit('setJieModalState', bool)
    },
  }
}
export default user;