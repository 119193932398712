/*
 * 权限相关接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例

//获取用户权限菜单
export const getUserMenu = (param) => {
  return axios.post(`/roleapi/auth/getUserMenu`, param);
}
//获取权限配置菜单
export const getAuthMenu = (param) => {
  return axios.post(`/roleapi/auth/getAuthMenu`, param);
}
//查询菜单
export const queryAll = (param) => {
  return axios.post(`/roleapi/menu/queryAll`, param);
}
//新增菜单
export const addMenuData = (param) => {
  return axios.post(`/roleapi/menu/add`, param);
}
//修改菜单
export const setMenu = (param) => {
  return axios.post(`/roleapi/menu/modify`, param);
}
// //删除菜单
export const deleteMenu = (param) => {
  return axios.post(`/roleapi/menu/delete`, param);
}
//查询岗位
export const getPosts = (param) => {
  return axios.post(`/roleapi/job/query`, param);
}
//新增岗位
export const addPosts = (param) => {
  return axios.post(`/roleapi/job/add`, param);
}
//修改岗位
export const setPosts = (param) => {
  return axios.post(`/roleapi/job/modify`, param);
}
//删除岗位
export const deletePosts = (param) => {
  return axios.post(`/roleapi/job/delete`, param);
}
//查询岗位下所有员工
export const queryEmployee = (param) => {
  return axios.post(`/roleapi/job/queryEmployee`, param);
}
//查询岗位权限
export const jobQuery = (param) => {
  return axios.post(`/roleapi/jobauth/query`, param);
}
//修改岗位权限
export const jobModify= (param) => {
  return axios.post(`/roleapi/jobauth/modify`, param);
}
//查询用户组
export const getGroup = (param) => {
  return axios.post(`/roleapi/group/query`, param);
}
//新增用户组
export const addGroup = (param) => {
  return axios.post(`/roleapi/group/add`, param);
}
//修改用户组
export const setGroup = (param) => {
  return axios.post(`/roleapi/group/modify`, param);
}
//删除用户组
export const deleteGroup = (param) => {
  return axios.post(`/roleapi/group/delete`, param);
}
//查询用户组下所有员工
export const queryGroupEmployee = (param) => {
  return axios.post(`/roleapi/group/queryEmployee`, param);
}
//查询用户组下未分组员工
export const queryNoGroupEmployee = (param) => {
  return axios.post(`/roleapi/group/queryNoGroupEmployee`, param);
}
//新增用户组下的用户
export const addGroupEmployee = (param) => {
  return axios.post(`/roleapi/group/addEmployee`, param);
}
//删除用户组下的用户
export const deleteGroupEmployee= (param) => {
  return axios.post(`/roleapi/group/deleteEmployee`, param);
}
//获取用户组权限
export const groupQuery= (param) => {
  return axios.post(`/roleapi/groupauth/query`, param);
}
//修改用户组权限
export const groupModify= (param) => {
  return axios.post(`/roleapi/groupauth/modify`, param);
}
//添加管理员账号
export const insertAdministrator= (param) => {
  return axios.post(`/personnelapi/userLogin/insertAdministrator`, param);
}
//重置管理员密码
export const updateAdminPassWord= (param) => {
  return axios.post(`/personnelapi/userLogin/updateAdminPassWord`, param);
}
//修改管理员账号
export const updateAdministrator= (param) => {
  return axios.post(`/personnelapi/userLogin/updateAdministrator`, param);
}
//查询管理员信息列表
export const selectAdminByPagination= (param) => {
  return axios.post(`/personnelapi/userLogin/selectAdminByPagination`, param);
}
//公司名称
export const selectLikeCompanyName= (param) => {
  return axios.post(`/personnelapi/userLogin/selectLikeCompanyName`, param);
}
//获取管理员权限菜单
export const getMenuAll= (param) => {
  return axios.post(`/roleapi/auth/getMenuAll`, param);
}
//获取管理员权限
export const getAdminMenu= (param) => {
  return axios.post(`/roleapi/auth/getAdminMenu`, param);
}
//设置管理员权限
export const setAdminMenu= (param) => {
  return axios.post(`/roleapi/auth/setAdminMenu`, param);
}