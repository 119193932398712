import { computed } from 'vue';

const importAll = requireContext => requireContext.keys().forEach(requireContext);

try {
  importAll(require.context('@/assets/icons', false, /\.svg$/));
} catch (error) {
  console.log(error);
}

export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },

  setup(props) {
    const iconName = computed(() => `#icon-${props.iconClass}`);
    const svgClass = computed(() => 'svg-icon');
    return {
      iconName,
      svgClass
    };
  }

};